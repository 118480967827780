/* .container {
  margin: auto;
  margin: 16px 0px;
} */
/* hr {
  opacity: 50%;
  width: 100px;
} */
/* .box {
  width: 360px;
  height: auto;
  max-height: 350px;
  border-radius: 5px;
  border: 3px solid #1a71e5;
  margin: auto;
  border-radius: 20px;
} */
/* .dateContainer {
  display: flex;
  justify-content: space-around;
  background: rgba(218 232 233 / 20%);
  margin: 10px 20px;
  padding: 5px 0px;
  border-radius: 10px;
} */
/* .TextColor {
  color: #7289da;
} */
/* .textColor {
  color: #7289da;
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
} */
/* .days-box {
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
} */
/* .unhandled {
  display: flex;
  justify-content: space-evenly;
  opacity: 35%;
} */
/* .unhandledTop {
  display: flex;
  justify-content: space-evenly;
  opacity: 35%;
  transform: skewX(-1.5deg);
} */
/* .width p {
  font-size: 15px;
  padding: 5px;
  font-style: normal;
  font-family: Roboto;
} */
/* .unhandledTop p{
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.80));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: scaleY(0.75);
} */
/* .unhandledBottom {
  display: flex;
  justify-content: space-evenly;
  opacity: 35%;
  transform: skewX(1.5deg);
} */
/* .unhandledBottom p{
  background: linear-gradient(to bottom, rgba(0,0,0,0.80), rgba(0,0,0,0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: scaleY(0.75);
} */
/* .handled {
  display: flex;
  justify-content: space-evenly;
  border-radius: 10px;
} */
/* .oppacity-days {
  opacity: 35%;
} */
/* .oppacity-daysTop {
  opacity: 35%;
  transform: skewX(-1.5deg);
} */
/* .oppacity-daysTop p {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.80));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: scaleY(0.75);
} */
/* .oppacity-daysBottom {
  opacity: 35%;
  transform: skewX(1.5deg);
} */
/* .oppacity-daysBottom p{
  background: linear-gradient(to bottom, rgba(0,0,0,0.80), rgba(0,0,0,0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: scaleY(0.75);
} */
/* .width {
  width: 33.33%;
  display: flex;
  justify-content: center;
}
.curve {
  width: 33.33%;
  display: flex;
  justify-content: center;
} */
/* .gradient-text {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.35)4%, rgba(0, 0, 0., 0.05) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */
/* .curve { */
  /* width: 33.33%; */
  /* display: flex; */
  /* justify-content: center; */
  /* transform-origin: top center; */
  /* transform: rotateX(90deg) skewY(30deg); */
  /* display: inline-block; */
/* } */
/* .addBtn {
  margin: 5px auto;
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
} */
.hide-class {
  overflow: hidden;
}
/* .timeDisplay {
  display: flex;
  justify-content: center; */
  /* margin-left: 35%; */
  /* list-style-type: none;
} */
/* .timeDisplayContainer {
  text-align: center;
  margin: auto;
} */
/* .timeListing {
  font-style: normal;
  font-family: Roboto;
  margin-top: 8px;
  font-size: 14px;
} */

@keyframes mymoveone {
  from {
    bottom: 50px;
  }
  to {
    bottom: 0px;
  }
}

.animation {
  position: relative;
  animation: mymove 0.2s;
}
@keyframes mymove {
  from {
    bottom: 50px;
  }
  to {
    bottom: 0px;
  }
}

.animationDown {
  position: relative;
  animation: mymove 0.2s;
}
@keyframes mymoveone {
  from {
    bottom: 0px;
  }
  to {
    bottom: 50px;
  }
}
