* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.mapbox-logo{
  display: none !important;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
